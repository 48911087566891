.Booking {
  background: white;
  min-height: 100%;
  padding: 64px 0 120px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1000;
}
.BookingHead {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0;
  display: flex;
  background: #fff;
  justify-content: flex-end;
  z-index: 100;
}
.BookingFormBody {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: wrap;
  margin-top: 25px;
  margin-bottom: 50px;
}
.BookingFormBody--grey {
  color: #777;
  font-size: 18px;
}
.BookingProcessBox {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 292px;
  margin-bottom: 32px;
  flex: 0 0 292px;
}
.BookingProcessBox p {
  color: rgb(128, 128, 128);
}

.BookingProcessBox h3 {
  margin-top: 0;
}

.BookingFooter {
  position: fixed;
  top: calc(100% - 135px);
  left: 0;
  right: 0;
  width: 100vw;
  background: linear-gradient(0deg, #fff, #fff 80%, hsla(0, 0%, 100%, 0));
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 135px;
}
.BookingBtn {
  width: 100%;
  height: 56px;
  display: block;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: #fff !important;
  border-radius: 4px;
  border: none;
  background: #006cb4;
  box-sizing: border-box;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  transition-property: background;
  will-change: background;
}
.BookingBtn:hover {
  background: #005187;
}

.BookingContainer {
  max-width: 736px;
  width: 100%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  background: white;
}
.BookingProcessNumber {
  background-color: rgb(242, 244, 247);
  width: 32px;
  margin-right: 20px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 32px;
  border-radius: 50%;
}
.BookingProcessNumber span {
  color: rgb(150, 155, 165);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .BookingContainer {
    padding: 45px 24px;
    box-sizing: border-box;
  }
}
.r-input-box {
  color: #000;
  border: 1px solid #000;
  padding: 0 15px;
  height: 58px;
  width: 100%;
  box-sizing: border-box;
}
.r-select__head {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.r-select.active .r-select__body {
  display: block;
}
.r-select__body {
  box-sizing: border-box;
  border: 1px solid;
  margin: 0;
  cursor: pointer;
  padding: 0;
  display: none;
}
.r-select__item {
  padding: 0 15px;
  user-select: none;
}
.r-select__item:hover {
  background: #1e90ff;
  color: white;
}
.r-checkbox input {
  display: none;
}
.r-checkbox label {
  height: 14px;
  width: 14px;
  border: 2px solid #000;
  display: block;
  opacity: 0.6;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}
.r-input__title,
.r-date__title,
.r-select__title,
.r-checkbox__title {
  padding-bottom: 5px;
}
.r-checkbox input:checked + label {
  opacity: 1;
}
.r-checkbox input:checked + label::after {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.r-form-group {
  margin-bottom: 25px;
}

.BookingForm-header,
.BookingForm-description {
  text-align: center;
}
.r-button {
  color: #ffffff;
  background-color: #006cb4;
  border-radius: 5px;
  height: 60px;
  border: none;
  padding-left: 60px;
  padding-right: 60px;
  cursor: pointer;
}
.Booking__btn-box {
  text-align: center;
}
.Booking__btn-box__preload {
  height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Booking__btn-box__preload div {
  display: block;
  background: url("assets/preloader.svg");
  width: 30px;
  height: 30px;
  background-size: cover;
}

.r-checkbox__body {
  display: flex;
}
.r-select__item,
.r-select__head span {
  list-style: none;
  user-select: none;
}
.BookingClose {
  background: url("assets/close.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: auto 0;
  margin-right: 30px;
}
.r-input-box.invalid {
  border: 1px solid red !important;
}
.privacypolicy {
  color: #00a32c;
  text-decoration: none;
}
.Booking__info {
  text-align: center;
  background: #62c584;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  font-family: "CirceRounded", Arial, sans-serif;
  font-size: 20px;
  line-height: 1.55;
}
#BookingForm__anchor {
  position: absolute;
  top: 0;
}
.dp-select-year,
.dp-select.dp-select-month,
.dp-next,
.dp-prev,
.current-month {
  cursor: pointer;
}

.r-input-box_date {
  position: relative;
  max-width: 245px;
  top: 0;
}
.r-input-box_date::after {
  content: url("assets/date.svg");
  position: absolute;
  right: 15px;
  top: 0;
  width: 20px;
  height: 20px;
  bottom: 0;
  margin: auto;
}

.datetime-picker.datetime-picker-popup .calendar {
  max-width: 235px;
  border: 1px solid;
  border-radius: 2px;
  padding: 10px;
  height: 210px;
  margin-top: 5px;
}
.datetime-picker.datetime-picker-popup .time {
  display: none;
}
.calendar-nav {
  padding: 3px 5px;
  background: #efefef;
  display: flex;
  justify-content: space-between;
}
.calendar-nav .prev-month,
.calendar-nav .next-month {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.calendar-nav .prev-month::before,
.calendar-nav .next-month::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  margin: -6px;
  border: 6px solid #666;
}
.calendar-nav .prev-month::before {
  border-color: transparent #666 transparent transparent;
}
.calendar-nav .next-month::before {
  border-color: transparent transparent transparent #666;
}
.calendar-nav .prev-month:hover::before {
  border-color: transparent #000 transparent transparent;
}
.calendar-nav .next-month:hover::before {
  border-color: transparent transparent transparent #000;
  transform: rotate(180gep);
}

.datetime-picker.datetime-picker-popup .calendar-days table > thead > tr > th {
  position: relative;
  padding: 0 6px;
  cursor: default;
  font-weight: normal;
  border: none;
  border-bottom: 1px solid #666;
  color: #777;
}
.datetime-picker.datetime-picker-popup
  .calendar-days
  table
  > tbody
  > tr
  > td.next,
.datetime-picker.datetime-picker-popup
  .calendar-days
  table
  > tbody
  > tr
  > td.prev {
  opacity: 0.4;
}

.calendar-days table > tbody > tr > td {
  text-align: center;
  cursor: pointer;
  z-index: 0;
  position: relative;
}

.calendar-months table > tbody > tr > td,
.calendar-years table > tbody > tr > td {
  cursor: pointer;
  z-index: 0;
  flex: 1;
  text-align: center;
  position: relative;
}

.calendar-days table > tbody > tr > td::before,
.calendar-months table > tbody > tr > td::before,
.calendar-years table > tbody > tr > td::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
  z-index: -1;
  border-radius: 50%;
}
.calendar-days table > tbody > tr > td::before {
  width: 28px;
  height: 28px;
}
.calendar-years table > tbody > tr > td::before {
  width: 44px;
  height: 22px;
}
.calendar-months table > tbody > tr > td::before {
  width: 65px;
  height: 22px;
}
.calendar-months table > tbody > tr > td:hover::before,
.calendar-years table > tbody > tr > td:hover::before {
  background-color: #bdbdbd;
}
.calendar-months table > tbody > tr > td.selected,
.calendar-years table > tbody > tr > td.selected {
  color: white;
}
.calendar-months table > tbody > tr > td.selected::before,
.calendar-years table > tbody > tr > td.selected::before {
  background-color: #000;
}
.calendar-months table,
.calendar-years table {
  width: 100%;
}
.calendar-months table > tbody,
.calendar-years table > tbody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.calendar-months table > tbody > tr,
.calendar-years table > tbody > tr {
  display: flex;
  justify-content: space-between;
}

.datetime-picker.datetime-picker-popup
  .calendar-days
  table
  > tbody
  > tr
  > td.selected {
  cursor: default;
  color: white;
}

.datetime-picker.datetime-picker-popup
  .calendar-days
  table
  > tbody
  > tr
  > td:hover:not(.selected)::before {
  background-color: #bdbdbd;
}
.datetime-picker.datetime-picker-popup
  .calendar-days
  table
  > tbody
  > tr
  > td.selected::before {
  background-color: #000;
}

.current-date {
  cursor: pointer;
}
