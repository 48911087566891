body,
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: Circe, "CirceRounded", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100%;
  background-color: #f6f6f6;
}
.top_panel {
  background: #fff;
  border: 1px solid #efefef;
}
.top_panel_inner {
  display: flex;
  justify-content: space-between;
  max-width: 1120px;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
}
.top_panel_header h1 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  font-family: Circe, "CirceRounded";
}
a.btnScheme,
.btnPopup {
  color: #505050;
  text-decoration: none;
  font-size: 14px;
  font-family: Circe, "CirceRounded";
  padding: 5px 15px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  vertical-align: top;
  transition: all 0.2s ease-out;
}
a.btnScheme {
  color: #505050 !important;
}
a.btnScheme:hover {
  border-color: #aeaeae;
  color: #000 !important;
}
.admin_interface_wrapper {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.admin_fields_form {
  margin: 0 auto;
  width: 220px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}
.admin_fields_form .input_text {
  display: block;
  padding: 4px 0 4px 12px;
  box-sizing: border-box;
  margin: 8px 0;
  height: 100%;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-family: Circe;
  font-size: 16px;
  color: #484848;
  letter-spacing: 0;
}
.top_panel_button {
  margin: 0 auto;
}
.Booking__err {
  background: #f95d51;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  display: none;
}
.Booking__err p {
  color: #fff;
  font-size: 18px;
}
.err-box {
  display: none;
}
.Booking__err.active,
.err-box.active {
  display: block;
}
